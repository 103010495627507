import { Component, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MailBoxService } from '../../../core/services/app/mailbox.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { DOCUMENT } from '@angular/common';
import { WindowReferenceService } from '../../../core/services/window-reference.service';

@Component({
  standalone: true,
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrl: './notification-bar.component.scss',
  imports: [
    TranslateModule,
    RouterModule
  ]
})
export class NotificationBarComponent implements OnInit {

  isShow = false
  isAuth = false;
  auth: any
  @Input() usMailBoxCount = 0

  constructor(
    private mailBoxService: MailBoxService,
    private localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private document: Document,
    private _windowRef: WindowReferenceService
  ) { }

  ngOnInit(): void {
    this.auth = JSON.parse(this.localStorageService.getItem('auth') || '{}');
    if (this.auth.Token) {
      this.isAuth = true
      let width = this._windowRef?.nativeWindow?.innerWidth;
      let marginTop = 140
      if (width < 1024) {
        marginTop = 100
      }
      this.changeMarginTop(marginTop)
    } else {
      this.isAuth = false
    }
    if (this.isAuth && this.auth.PaidMember) {
      this.isShow = true
      //this.getMailBox()
    }
  }

  close() {
    this.isShow = false
    let width = this._windowRef?.nativeWindow?.innerWidth;
    let marginTop = 100
    if (width < 1024) {
      marginTop = 50
    }
    this.changeMarginTop(marginTop)
  }

  getMailBox() {
    this.mailBoxService.getmailBox().subscribe(result => {
      this.usMailBoxCount = result.Data.length
    })
  }

  changeMarginTop(value: number) {
    const routerWrapper = this.document.getElementById('routerWrapper');
    if (routerWrapper) {
      routerWrapper.style.setProperty('margin-top', `${value}px`, 'important');
    }
  }
}
