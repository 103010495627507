import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Component({
  standalone:true,
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  imports:[],
})
export class SpinnerComponent implements OnInit {
  anim:any
  loader = false
  constructor(public loaderService: LoaderService) { }
  ngOnInit(): void {
   this.loaderService.getSubject().pipe(debounceTime(6000)).subscribe(result => {
    this.loader = result
   })
  }
}


