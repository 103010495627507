import { Component, ElementRef, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { ISignInResponse } from '../../../core/models/auth/signIn';
import { AuthService } from '../../../core/services/app/auth.service';
import { SnackBarService } from '../../../core/services/snackbarService';
import { LoaderServices } from '../../../core/services/loaderService';
import { BfmService } from '../../../core/services/app/bfm.service';
import { BasketCountService } from '../../../core/services/app/basketcount.service';
import { ShoppingService } from '../../../core/services/app/shopping.service';
import { forbiddenSearchWords } from '../../../core/utilities/shopping/forbidden-search-words-constants';
import { WatcherService } from '../../services/watcher.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MetatagService } from '../../services/metatag.service';
import { CommonModule, DOCUMENT, Location } from '@angular/common';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { WindowReferenceService } from '../../../core/services/window-reference.service';
import { ClickOutsideDirective } from '../../../core/directives/click-outside.directive';
import { NotificationBarComponent } from '../notification-bar/notification-bar.component';
import { MailBoxService } from '../../../core/services/app/mailbox.service';

@Component({
  standalone:true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports:[
    CommonModule,
    TranslateModule,
    RouterModule,
    ClickOutsideDirective,
    NotificationBarComponent
  ],
  
})
export class HeaderComponent implements OnInit {

  // AZ Landing Page
  headerView: string = '';
  // AZ Landing Page
  isLoading = false;
  langValue = 'en';
  langButtonText = 'EN';
  auth: ISignInResponse | null;
  @Input() showSearchBar: boolean = true;
  @Input() showMobileSearchBar: boolean = true;
  cartItemCount = 0;
  forbiddenKeyWords = forbiddenSearchWords;
  userData: any;
  menuUserShow = false;
  burgerMenuShow = false;
  currentLang = this.langValue
  urlLang = this.langValue
  usMailBoxCount = 0

  constructor(
    private snackBar: SnackBarService,
    public router: Router,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private translate: TranslateService,
    private location2: Location,
    private bfmService: BfmService,
    private basketCountService: BasketCountService,
    private shoppingService: ShoppingService,
    private watcherService: WatcherService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private metatagService: MetatagService,
    @Inject(DOCUMENT) private document: Document,
    private _windowRef: WindowReferenceService,
    private mailBoxService: MailBoxService,
  ) {

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.urlLang = event.url.split('/')[1]
        if (!['tr', 'en', '/tr', '/en'].includes(this.urlLang)) {
          this.urlLang = this.translate.currentLang
        }
        this.changeLanguage(this.urlLang)
        if (event.url === '/signup') {
          this.showMobileSearchBar = false;
        }
      }
    });
    const lang = this.localStorageService.getItem('lang');
    if (lang === undefined || lang === null) {
      translate.setDefaultLang('tr');
      translate.use('tr');
      this.localStorageService.setItem('lang', 'tr');
    } else {
      translate.setDefaultLang(lang);
      translate.use(lang);
      this.langValue = lang === 'tr' ? 'en' : 'tr';
      lang === 'tr' ? this.langButtonText = 'EN' : this.langButtonText = 'TR';
    }
    this.auth = JSON.parse(this.localStorageService.getItem('auth') + '');

    this.basketCountService.currentBasketCount.subscribe(count => this.cartItemCount = count);

    // AZ Landing Page 
    this.watcherService.onHeaderViewUpdate.subscribe(view => {
      view ? this.headerView = view : this.headerView = 'standard';
      // AZ Landing Page
      if (this.headerView === 'azlp-header') {
        this.translate.use('tr');
      }
      // AZ Landing Page

    })
    // AZ Landing Page

  }

  ngOnInit() {
    this.currentLang = this.translate.currentLang
    this.auth = JSON.parse(this.localStorageService.getItem('auth') + '');
    if (this.auth?.Token && this.auth.PaidMember) {
      this.getMailBox()
    }
    this.authService.getAuthInformation().subscribe((data: ISignInResponse) => {
      this.auth = data;
    });
    const authArg: string | null = this.localStorageService.getItem('auth');
    if (authArg !== null && authArg !== undefined && this.auth?.PaidMember) {
      this.getCurrentUser();
      this.getBasketList();
    }
  }

  getMailBox() {
    this.mailBoxService.getmailBox().subscribe(result => {
      this.usMailBoxCount = result.Data.length
    })
  }

  changeLanguage = (lang: string) => {

    this.translate.use(lang);
    this.currentLang = lang
    let path = this.location2.path().slice(3)

    if (this.headerView === 'azlp-header') {
      if (lang === 'tr') {
        this.langValue = 'en';
        this.localStorageService.setItem('lang', 'tr');
        this.langButtonText = this.langValue.toUpperCase();
      } else {
        this.langValue = 'tr';
        this.localStorageService.setItem('lang', 'en');
        this.langButtonText = this.langValue.toUpperCase();
      }
    } else {
      if (lang === 'tr') {
        this.langValue = 'en';
        this.localStorageService.setItem('lang', 'tr');
        this.langButtonText = this.langValue.toUpperCase();
      } else {
        this.langValue = 'tr';
        this.localStorageService.setItem('lang', 'en');
        this.langButtonText = this.langValue.toUpperCase();
      }
    }
    
    this.metatagService.setMetaTag();
    this.location2.replaceState(lang + path);
  }

  logout = () => {
    this.authService.logout().subscribe((data) => {
      this.auth = null;
      this.authService.setAuthInformation(this.auth)
      this.localStorageService.removeItem('signinTime');
      this.localStorageService.removeItem('auth');
      this.router.navigate([this.translate.currentLang + '']);
      this.cartItemCount = 0;
    }, (err: any) => {
      this.snackBar.error(err.Message);
    });
  }

  navigateRouter = (page: any) => {
    this.router.navigate(page);
  }
  // AZ Landing Page
  navigateAzRouter = (page: any) => {
    location.href = `https://amerikadaniste.az/${page}`;
  }
  // AZ Landing Page

  getBasketList = () => {

    this.bfmService.getBfmProductList().subscribe((data) => {

      if (data.Result === 200) {
        this.cartItemCount = data.Data.length;
        this.basketCountService.updateCount(this.cartItemCount);
      }
      else if (data.Result === 204) {
        this.cartItemCount = 0;
      }
      else {
        this.snackBar.error(this.translate.instant('AN_ERROR_OCCURED_DURING_GETTING_BASKET_ITEMS'));
      }
    }, (err) => {

      this.snackBar.error(err.error.Message);
    });
  }

  goToBasket = () => {
    this.router.navigate([this.translate.currentLang + '/shopping/my-basket']);
  }

  search = (keyword: string) => {
    this.localStorageService.setItem('lastSearchKeyword', keyword)
    if (keyword !== null && keyword !== '' && (keyword ? keyword : '').toLowerCase() !== 'amazon') {
      let searchText = '';
      if (this.forbiddenKeyWords.includes(keyword)) {
        searchText = '';
      } else {
        this.googleAnalyticsService.gtag("event", "search_web", {
          user_id:this.auth ? this.auth.UserId : "0",
          value: keyword,
        })
        
        this.shoppingService.translateSearchText(keyword).subscribe((data) => {
          searchText = data[0][0][0];

          let url;
          // AZ Landing Page 
          if (this.headerView === 'azlp-header') {
            url = 'https://amerikadaniste.az/shopping?search=' + searchText;
          } else {
            url = this.translate.currentLang + '/shopping'
          }
          this.router.navigate([url], { queryParams: { search: searchText } })
          //this._windowRef.nativeWindow.open(url, '_self');
        }, (err) => {

          this.snackBar.error(err.Message);
        });
      }
    }
  }

  onSearchInputFocus = () => {
    
    const vw = Math.max(this.document.documentElement.clientWidth || 0, this._windowRef?.nativeWindow?.innerWidth || 0);
    const navbar = this.document.getElementById('navbar-container');
    const rightSideContainer = this.document.getElementById('right-side-container');
    const logoContainer = this.document.getElementById('logo-container');

    if (vw > 1200) {
      if (navbar && rightSideContainer) {
        navbar.style.display = 'none';
        rightSideContainer.style.width = '75%';
      }
    } else {
      if (logoContainer && rightSideContainer) {
        logoContainer.style.display = 'none';
        rightSideContainer.style.width = '90%';
      }
    }
    

  }

  onSearchInputLostFocus = () => {
    
    const vw = Math.max(this.document.documentElement.clientWidth || 0, this._windowRef?.nativeWindow?.innerWidth || 0);
    const navbar = this.document.getElementById('navbar-container');
    const rightSideContainer = this.document.getElementById('right-side-container');
    const logoContainer = this.document.getElementById('logo-container');
  

    if (vw > 1200) {
      if (navbar && rightSideContainer) {
        navbar.style.display = 'flex';
        rightSideContainer.style.width = 'auto';
      }
    } else {
      if (logoContainer && rightSideContainer) {
        logoContainer.style.display = 'none';
        rightSideContainer.style.width = 'auto';
      }
    }
    
  }



  getCurrentUser = () => {

    this.authService.getCurrentUser().subscribe((data) => {
      this.localStorageService.setItem('currentUser',JSON.stringify(data.Data))
      this.userData = data.Data;
      if (this.userData.SuitableForAdvantageMembership && !this.userData.PaidMember) {
        this.navigateSigninOptions()
      }

    }, (err) => {
      this.snackBar.error(err.error.Message);

    });
  }

  navigateSigninOptions() {
    this.router.navigate([this.translate.currentLang + '/signin-options'])
  }

  openBlog = () => {
    this._windowRef.nativeWindow.open('https://blog.amerikadaniste.com');
  }

  openSSS = () => {
    this._windowRef.nativeWindow.open('https://yardim.amerikadaniste.com');
  }

  openBfm = () => {
    const auth = JSON.parse(this.localStorageService.getItem('auth') + '');
    if (auth === null) {
      this.router.navigate([this.translate.currentLang + '/buy-for-me']);
    } else {
      this.router.navigate([this.translate.currentLang + '/user/buy-for-me/new']);
    }
  };

  goToAzSignIn = () => {
    location.href = 'https://amerikadaniste.az/signin';
  }
}
