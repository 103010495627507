@if(footerView !== 'azlp-header') {

<section class="app-footer">
  <div class="instagram-container">
    <div class="follow-us-on-instagram">
      <div class="instagram-address">
        <img width="25" height="25" src="assets/icons/instagram-icon.png" async>
        <a class="instagram-text" href="https://www.instagram.com/Amerikadaniste/" target="_blank"> <span
            class="slash">/</span>&nbsp;amerikadaniste</a>
      </div>
      <div class="follow">
        <a class="follow-text" href="https://www.instagram.com/Amerikadaniste/" target="_blank">{{'FOLLOW' |
          translate}}</a>
        <div class="gradient-line"></div>
      </div>
    </div>
  </div>
  <div class="footer column-layout-centerized">
    <div class="about-box">
      <div class="col-start-layout follow-us-container">
        <h2 class="follow-us-title">
          {{ 'FOLLOW_US'| translate }}
        </h2>
        <p class="follow-us-text">
          {{ 'BE_AWARE'| translate}}
        </p>
      </div>
      <div class="row-start-layout mail-container">
        <div class="footer-email-input">
          <input #emailAddress type="email" placeholder="{{'E_MAIL_ADDRESS' | translate}}">
        </div>

        <div class="let-me-know-button" (click)="subscribeMail()">
          {{ 'LET_ME_KNOW' | translate }}
        </div>
      </div>
    </div>
    <div class="row-space-layout-around contact-panel">
      <div class="col-start-layout column-container">
        <p class="column-title">{{'POPULAR_BRANDS' | translate}}</p>
        <div class="row-start-layout popular-brands-container">
          <div class="col-start-layout column-container">
            <a [href]="lang + '/shopping?brand=nike'" target="_blank">Nike</a>
            <a [href]="lang + '/shopping?brand=old-navy'">Gap</a>
            <a [href]="lang + '/shopping?brand=hugoboss'" target="_blank">Hugo Boss</a>
            <a [href]="lang + '/shopping?brand=adidas'" target="_blank">Adidas</a>
            <a [href]="lang + '/shopping?brand=calvinklein'" target="_blank">Calvin Klein</a>
            <a [href]="lang + '/shopping?brand=guess'" target="_blank">Guess</a>
          </div>

          <div class="col-start-layout column-container">
            <a [href]="lang + '/shopping?brand=tommyhilfiger'" target="_blank">Tommy Hilfiger</a>
            <a [href]="lang + '/shopping?brand=ralphlauren'" target="_blank">Ralph Lauren</a>
            <a [href]="lang + '/shopping?brand=armaniexchange'" target="_blank">Armani Exchange</a>
            <a [href]="lang + '/shopping?brand=harleydavidson'">Harley-Davidson</a>
            <a [href]="lang + '/shopping?brand=lacoste'">Lacoste</a>
            <a [href]="lang + '/shopping?brand=dkny'">DKNY</a>
          </div>
        </div>
      </div>
      <div class="col-start-layout our-partners-container">
        <p class="column-title">{{'OUR_PARTNERS' | translate}}</p>
        <a href="https://www.shippn.com/" target="_blank"><img src="/assets/icons/shippn-logo.png" async></a>
        <a href="https://www.boxinus.com/" target="_blank"><img src="/assets/icons/boxinus-logo.png" async></a>
      </div>
      <div class="col-start-layout column-container">
        <p class="column-title">{{'ON_SOCIAL_MEDIA' | translate}}</p>
        <div class="row-start-layout social-icons-container">
          <a href="https://www.facebook.com/Amerikadaniste" target="_blank"><img width="11" class="social-icons-footer"
              style="filter:invert(1)" src="/assets/icons/facebook-icon-white.png" async></a>
          <a href="https://www.instagram.com/Amerikadaniste/" target="_blank"><img width="19"
              class="social-icons-footer" src="/assets/icons/instagram-icon-white.png" async></a>
          <a href="https://twitter.com/Amerikadaniste" target="_blank"><img width="19" class="social-icons-footer"
              style="filter:invert(1)" src="/assets/icons/twitter-icon-white.png" async></a>
          <a href="https://www.youtube.com/channel/UCEn0pGVDGlAZlwKUVHnmOOA" target="_blank"><img width="25"
              class="social-icons-footer" style="filter:invert(1)" src="/assets/icons/youtube-icon-white.png" async></a>
          <a href="https://tr.pinterest.com/amerikadaniste/" target="_blank"><img width="17" class="social-icons-footer"
              style="filter:invert(1)" src="/assets/icons/pinterest-icon-white.png" async></a>
        </div>
      </div>

    </div>

    <div class="footer-bottom-level">
      <div class="footer-navigation">
        <div class="footer-nav-items">
          <a [href]="lang + '/about-us'" class="footer-nav-text">{{'ABOUT_US' | translate}}</a>
          <span class="white-seperator">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
          <a [href]="lang + '/privacy-policy'" class="footer-nav-text">{{'PRIVACY_POLICY_LOWER' | translate}}</a>
          <span class="white-seperator">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
          <a [href]="lang + '/cookies-policy'" class="footer-nav-text">{{'COOKIE_POLICY' | translate}}</a>
          <span class="white-seperator">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
          <a [href]="lang + '/contact'" class="footer-nav-text">{{'CONTACT_US' | translate}}</a>
          <span class="white-seperator">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
          <a [href]="lang + '/reliable-address-for-shopping-from-america'"
            class="footer-nav-text">{{'IS_AMERIKADANISTE_RELIABLE' | translate}}</a>
            <span class="white-seperator">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
            <a [href]="lang + '/amerikadaniste-advantage'"
            class="footer-nav-text">{{'OUR_ADVANTAGES' | translate}}</a>
        </div>

      </div>
      <div class="col-start-layout information-protected">
        <p class="protected-information-font">Your data protected by</p>
        <img width="128" src="/assets/icons/SSL3-SECURE-min.png" async>
      </div>
      <div class="col-start-layout">
        <p class="copyright-font">Copyright Amerikadaniste © 2006 - {{currentYear}}</p>
      </div>
    </div>
  </div>
</section>
}
<!-- AZ Landing Page -->
@if(footerView === 'azlp-header') {
<section class="app-footer">
  <div class="instagram-container">
    <div class="follow-us-on-instagram">
      <div class="instagram-address">
        <img width="25" height="25" async src="assets/icons/instagram-icon.png">
        <a class="instagram-text" href="https://www.instagram.com/Amerikadaniste/" target="_blank"> <span
            class="slash">/</span>&nbsp;amerikadaniste</a>
      </div>
      <div class="follow">
        <a class="follow-text" href="https://www.instagram.com/Amerikadaniste/" target="_blank">{{'FOLLOW' |
          translate}}</a>
        <div class="gradient-line"></div>
      </div>
    </div>
  </div>
  <div class="footer column-layout-centerized">
    <div class="about-box">
      <div class="col-start-layout follow-us-container">
        <h1 class="follow-us-title">
          {{ 'FOLLOW_US'| translate }}
        </h1>
        <p class="follow-us-text">
          {{ 'BE_AWARE'| translate}}
        </p>
      </div>
      <div class="row-start-layout mail-container">
        <div class="footer-email-input">
          <input #emailAddress type="email" placeholder="{{'E_MAIL_ADDRESS' | translate}}">
        </div>

        <div class="let-me-know-button" (click)="subscribeMail()">
          {{ 'LET_ME_KNOW' | translate }}
        </div>
      </div>
    </div>
    <div class="row-space-layout-around contact-panel">
      <div class="col-start-layout column-container">
        <p class="column-title">{{'POPULAR_BRANDS' | translate}}</p>
        <div class="row-start-layout popular-brands-container">
          <div class="col-start-layout column-container">
            <a [href]="'/shopping?search=nike'" target="_blank">Nike</a>
            <a [href]="'/shopping?search=gap'" target="_blank">Gap</a>
            <a [href]="'/shopping?search=hugoboss'" target="_blank">Hugo Boss</a>
            <a [href]="'/shopping?search=adidas'" target="_blank">Adidas</a>
            <a [href]="'/shopping?search=calvinklein'" target="_blank">Calvin Klein</a>
            <a [href]="'/shopping?search=guess'" target="_blank">Guess</a>
          </div>

          <div class="col-start-layout column-container">
            <a [href]="'/shopping?search=tommy-hilfiger'" target="_blank">Tommy Hilfiger</a>
            <a [href]="'/shopping?search=ralphlauren'" target="_blank">Ralph Lauren</a>
            <a [href]="'/shopping?search=armani-exchange'" target="_blank">Armani Exchange</a>
            <a [href]="'/shopping?search=harley-davidson'" target="_blank">Harley-Davidson</a>
            <a [href]="'/shopping?search=lacoste'" target="_blank">Lacoste</a>
            <a [href]="'/shopping?search=dkny'" target="_blank">DKNY</a>
          </div>
        </div>
      </div>
      <div class="col-start-layout our-partners-container">
        <p class="column-title">{{'OUR_PARTNERS' | translate}}</p>
        <a href="https://www.shippn.com/" target="_blank"><img async src="/assets/icons/shippn-logo.png"></a>
        <a href="https://www.boxinus.com/" target="_blank"><img async src="/assets/icons/boxinus-logo.png"></a>
      </div>
      <div class="col-start-layout column-container">
        <p class="column-title">{{'ON_SOCIAL_MEDIA' | translate}}</p>
        <div class="row-start-layout social-icons-container">
          <a href="https://www.facebook.com/Amerikadaniste" target="_blank"><img width="11" class="social-icons-footer"
              style="filter:invert(1)" async src="/assets/icons/facebook-icon-white.png"></a>
          <a href="https://www.instagram.com/Amerikadaniste/" target="_blank"><img width="19"
              class="social-icons-footer" async src="/assets/icons/instagram-icon-white.png"></a>
          <a href="https://twitter.com/Amerikadaniste" target="_blank"><img width="19" class="social-icons-footer"
              style="filter:invert(1)" async src="/assets/icons/twitter-icon-white.png"></a>
          <a href="https://www.youtube.com/channel/UCEn0pGVDGlAZlwKUVHnmOOA" target="_blank"><img width="25"
              class="social-icons-footer" style="filter:invert(1)" async src="/assets/icons/youtube-icon-white.png"></a>
          <a href="https://tr.pinterest.com/amerikadaniste/" target="_blank"><img width="17" class="social-icons-footer"
              style="filter:invert(1)" async src="/assets/icons/pinterest-icon-white.png"></a>
        </div>
      </div>

    </div>

    <div class="footer-bottom-level">
      <div class="footer-navigation">
        <div class="footer-nav-items">
          <a href="https://amerikadaniste.az/about-us" class="footer-nav-text">{{'ABOUT_US' | translate}}</a>
          <span class="white-seperator">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
          <a href="https://amerikadaniste.az/privacy-policy" class="footer-nav-text">{{'PRIVACY_POLICY_LOWER' |
            translate}}</a>
          <span class="white-seperator">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
          <a href="https://amerikadaniste.az/cookies-policy" class="footer-nav-text">{{'COOKIE_POLICY' | translate}}</a>
          <span class="white-seperator">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
          <a href="https://amerikadaniste.az/contact" class="footer-nav-text">{{'CONTACT_US' | translate}}</a>
        </div>
        <div class="col-start-layout information-protected">
          <p class="protected-information-font">Your data protected by</p>
          <img width="128" async src="/assets/icons/SSL3-SECURE-min.png">
        </div>
      </div>
      <div class="col-start-layout">
        <p class="copyright-font">Copyright Amerikadaniste © 2006 - 2021</p>
      </div>
    </div>
  </div>
</section>
}
@if(translate.currentLang === 'tr') {
<iframe async width="250" height="30" style="border: 0; position: fixed; right:0; bottom:0; z-index: 999;"
  src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=green&size=compact&radius=4px&label=Ayrıcalıklı Hizmetler&number=902122323397"></iframe>
}
@if(translate.currentLang === 'en') {
<iframe async width="250" height="30" style="border: 0; position: fixed; right:0; bottom:0; z-index: 999;"
  src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=green&size=compact&radius=4px&label=Exclusive Services&number=902122323397"></iframe>
}