import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../core/services/snackbarService';
import { WatcherService } from '../../services/watcher.service';
import { AuthService } from '../../../core/services/app/auth.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';

@Component({
  standalone:true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports:[
    TranslateModule,
  ]
})
export class FooterComponent {
  isLoading = false;
  @ViewChild('body') body: ElementRef | undefined;
  @ViewChild('emailAddress') emailAddress!: ElementRef;

  footerView: string = '';
  tokenData: any;
  accessToken = '';
  currentYear = new Date().getFullYear()
  lang = ''

  constructor(
    private authService: AuthService,
    private snackBar: SnackBarService,
    public translate: TranslateService,
    private watcherService: WatcherService,
    private localStorageService:LocalStorageService
    ) {
    this.lang = this.localStorageService.getItem('lang') || ''
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang
    })
    
    // AZ Landing Page 
    this.watcherService.onHeaderViewUpdate.subscribe(view => {
      view ? this.footerView = view : this.footerView = 'standard';
      // AZ Landing Page
      if (this.footerView === 'azlp-header') {
        this.translate.use('tr');
      }
      // AZ Landing Page

    })
    // AZ Landing Page
  }

  subscribeMail = () => {
    const email = this.emailAddress?.nativeElement.value;
    if (email == '' || email == null) {
      this.snackBar.error(this.translate.instant('ENTER_VALID_EMAIL'));
      return;
    }

    this.authService.subscribeHandler(email).subscribe((data: any) => {
      if (data.Result == 200) {
        this.snackBar.success(this.translate.instant('YOU_HAVE_SUCCESSFULLY_REGISTERED_FOR_NEWSLETTER'));
        this.emailAddress.nativeElement.value = ''
      } else {
        this.snackBar.error(data.Message);
      }
    }, (err) => {
      this.snackBar.error(err.error.message);
    });
  }

  
}
