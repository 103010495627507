@if(auth && usMailBoxCount > 0) {
  <app-notification-bar [usMailBoxCount]="usMailBoxCount"></app-notification-bar>
}

<div class="container" *ngIf="headerView !== 'azlp-header'">
  <div class="header-container">
    <button  (clickOutside)="burgerMenuShow = false" (click)="burgerMenuShow = !burgerMenuShow" class="mat-icon-button menu-button">
      <img class="burger-menu-icon">
    </button>
    <div class="logo-container" id="logo-container">
      <a [routerLink]="currentLang" routerLinkActive="active" target="_self">
        <img src="assets/img/logo.png" async/>
      </a>
    </div>
    <div class="navbar-container" id="navbar-container">
      <ul>
        <li><a [routerLink]="currentLang + '/shopping'" routerLinkActive="active" target="_self">{{'SHOPPING' | translate}}</a></li>
        <li><a [routerLink]="currentLang + '/prices'" routerLinkActive="active"  target="_self">{{'PRICES' | translate}}</a></li>
        <li><a [routerLink]="currentLang + '/how-it-works'" routerLinkActive="active"  target="_self">{{'HOW_IT_WORKS' | translate}}</a></li>
        <li><a [routerLink]="currentLang + '/buy-for-me'" routerLinkActive="active"  target="_self">{{'ORDERS_BUY_FOR_ME' | translate}}</a></li>
        <li><a href="https://blog.amerikadaniste.com/" target="_self">{{'BLOG' | translate}}</a></li>
        <li><a href="https://yardim.amerikadaniste.com" target="_self">{{'FAQ' | translate}}</a></li>
        <li><a [routerLink]="currentLang + '/contact'" routerLinkActive="active"  target="_self">{{'CONTACT_POLICY' | translate}}</a></li>
      </ul>
    </div>
    <div class="right-side-container" id="right-side-container">
      <div class="search-container" >
        <div class="search-field">
            <input class="search-input"  
            #searchParam 
            (keydown.enter)="search(searchParam.value)" 
            placeholder="{{'TYPE_THE_PRODUCT_THAT_YOU_WANT_TO_SEARCH' | translate}}"
            id="search-input" 
            (focus)="onSearchInputFocus()" 
            (focusout)="onSearchInputLostFocus()">
            <img  src="assets/icons/icon-search.png"  (click)="search(searchParam.value)" style="cursor: pointer;margin-left: -16px;padding-bottom: 5px;" async>
        </div>      
      </div>
      <div class="buttons-container">
       
        <div class="shopping-cart" *ngIf="cartItemCount > 0">
          <div class="cart-item" (click)="goToBasket()">
              <img class="cart-img" src="/assets/icons/ai-icon-black.png" async>
              <div class="basket-count">
                {{cartItemCount}}
              </div>
          </div>
        </div>
        <div class="shopping-cart empty-basket" *ngIf="cartItemCount === 0">
          <div class="cart-item" (click)="goToBasket()">
              <img class="cart-img" src="/assets/icons/empty-basket.png" async>
              <div class="basket-count">
                {{cartItemCount}}
              </div>
          </div>
        </div>
        <button *ngIf="auth === undefined || auth === null"  (click)="navigateRouter([currentLang +'/signin'])"
                class="login-button">{{'SIGNIN' | translate}}
        </button>
        <button *ngIf="auth" (clickOutside)="menuUserShow = false" (click)="menuUserShow = !menuUserShow" class="user-button">
          <img class="user-icon" src="/assets/icons/user-icon.png" async/>
          @if(auth && usMailBoxCount > 0) {
          <div class="basket-count" style="background-color: #000 !important;margin-left: 26px !important;margin-top: -32px !important;">
            {{usMailBoxCount}}
          </div>
        }
        </button>
        <button  class="language-button" (click)="changeLanguage(langValue)">{{langButtonText}}</button>
      </div>
    </div>
    <div class="burger-menu menu-panel"  *ngIf="burgerMenuShow">
      <button *ngIf="auth !== undefined && auth !== null" class="mat-menu-item" (click)="navigateRouter([currentLang +'/user/mailbox/list'])">{{'MAILBOX_US' | translate}} {{usMailBoxCount == 0 ? null : '(' + usMailBoxCount + ')'}}</button>
      <button *ngIf="auth !== undefined && auth !== null" class="mat-menu-item" (click)="navigateRouter([currentLang +'/user/orders/shopping'])">{{'ORDERS' | translate}}</button>
      <button *ngIf="auth !== undefined && auth !== null" class="mat-menu-item" (click)="navigateRouter([currentLang +'/user/my-account/my-membership-information'])">{{'MY_ACCOUNT' | translate}}</button>
      <button *ngIf="auth !== undefined && auth !== null" class="mat-menu-item" (click)="navigateRouter([currentLang +'/user/favourites/list'])">{{'FAVOURITE_PRODUCTS' | translate}}</button>
      <button class="mat-menu-item" (click)="navigateRouter([currentLang +'/shopping'])">{{'SHOPPING' | translate}}</button>
      <button class="mat-menu-item" (click)="navigateRouter([currentLang +'/prices'])">{{'PRICES' | translate}}</button>
      <button class="mat-menu-item" (click)="navigateRouter([currentLang +'/how-it-works'])">{{'HOW_IT_WORKS' | translate}}</button>
      <button class="mat-menu-item" (click)="openBfm()">{{'ORDERS_BUY_FOR_ME' | translate}}</button>
      <button class="mat-menu-item" (click)="openBlog()">{{'BLOG' | translate}}</button>
      <button class="mat-menu-item" (click)="openSSS()">{{'FAQ' | translate}}</button>
      <button class="mat-menu-item" (click)="navigateRouter([currentLang +'/contact'])">{{'CONTACT_POLICY' | translate}}</button>
     
      <div  class="mat-menu-item menu-button-container">
        <button *ngIf="auth === undefined || auth === null"  class=" signin-button" (click)="navigateRouter([currentLang +'/signin'])">{{'SIGNIN' | translate}}</button>
        <button *ngIf="auth === undefined || auth === null"  class=" signup-button" (click)="navigateRouter([currentLang +'/signup'])">{{'SIGNUP' | translate}}</button>
        <button *ngIf="auth !== undefined && auth !== null"  class=" signup-button logout-button" (click)="logout()">{{'LOG_OUT' | translate}}</button>
      </div>
      <div  class="mat-menu-item menu-button-container">
        <button class="  lang-flag-button" (click)="changeLanguage('en')" *ngIf="langValue === 'en'">
          <img src="/assets/icons/us-flag.png" async>
        </button>
        <button  class=" lang-flag-button" (click)="changeLanguage('tr')" *ngIf="langValue === 'tr'">
          <img src="/assets/icons/tr-flag.png" async>
        </button>
        <!-- <button  class="lang-flag-button">
          <img src="/assets/icons/az-flag.png" async>
        </button> -->
      </div>
    </div>
    <div class="user-panel"  *ngIf="menuUserShow">
      <div class="user-panel-content">
      <div class="mat-menu-item user-info-item" (click) = "navigateRouter([currentLang +'/user/my-account/my-membership-information'])" *ngIf="userData">
        <div class="row-layout-space-between-center user-info-container">
          <img class="user-inside-menu-icon" src="assets/icons/user.png" async/>
          <div class="column-layout-start user-info">
            <div class="user-info-text">{{userData.Name}} {{userData.Surname}}</div>
            <div class="user-info-text">{{userData.Mail}}</div>
          </div>
        </div>
      </div>
      <button class="mat-menu-item"  (click) = "navigateRouter([currentLang +'/user/orders/shopping'])"><img src="assets/icons/order.png" async/>{{'ORDERS' | translate}}</button>
      <button class="mat-menu-item" (click) = "navigateRouter([currentLang +'/user/mailbox/list'])"><img src="assets/icons/mailbox.png" async/>{{'MAILBOX' | translate}} {{usMailBoxCount == 0 ? null : '(' + usMailBoxCount + ')'}}</button>
      <button class="mat-menu-item" (click) = "navigateRouter([currentLang +'/user/buy-for-me/new'])"><img src="assets/icons/bfm.png" async/>{{'ORDERS_BUY_FOR_ME' | translate}}</button>
      <button class="mat-menu-item" (click) = "navigateRouter([currentLang +'/user/my-account/my-membership-information'])"><img src="assets/icons/user.png" async/>{{'MY_ACCOUNT' | translate}}</button>
      <button class="mat-menu-item" (click) = "navigateRouter([currentLang +'/user/awards/list'])"><img src="assets/icons/prizes.png" async/>{{'MY_AWARDS' | translate}}</button>
      <button class="mat-menu-item" (click)="navigateRouter([currentLang +'/user/favourites/list'])"><img src="assets/icons/black-heart.png" async/>{{'FAVOURITE_PRODUCTS' | translate}}</button>
      <button class="mat-menu-item" (click)="logout()"><img src="assets/icons/logout-icon.png" async/>{{'LOG_OUT' | translate}}</button>
    </div>
    </div>
  </div>
  <div class="mobile-search-container" *ngIf="showMobileSearchBar" [style.height]="!showMobileSearchBar ? '0' : '45px'">
    <div class="mobile-search-field">
      <input class="search-input"  
      #searchParam 
      (keydown.enter)="search(searchParam.value)" 
      placeholder="{{'TYPE_THE_PRODUCT_THAT_YOU_WANT_TO_SEARCH' | translate}}"
      id="search-input" >
      <img  src="assets/icons/icon-search.png"  (click)="search(searchParam.value)" style="cursor: pointer;margin-left: -16px;padding-top: 10px; width: 16px; height:16px;" async>
  </div>         
  </div>

</div>


<!-- AZ Landing Page Header -->
<div class="container" *ngIf="headerView === 'azlp-header'">
  <div class="header-container">
    <button  (clickOutside)="burgerMenuShow = false" (click)="burgerMenuShow = !burgerMenuShow" class="mat-icon-button menu-button">
      <img class="burger-menu-icon">
    </button>
    <div class="logo-container" id="logo-container">
      <a href="https://amerikadaniste.az" routerLinkActive="active" target="_self">
        <img src="assets/img/logo.png" async/>
      </a>
    </div>
    <div class="navbar-container" id="navbar-container">
      <ul>
        <li><a href="https://amerikadaniste.az/shopping"  target="_self">{{'SHOPPING' | translate}}</a></li>
        <li><a href="https://amerikadaniste.az/prices"  target="_self">{{'PRICES' | translate}}</a></li>
        <li><a href="https://amerikadaniste.az/how-it-works"  target="_self">{{'HOW_IT_WORKS' | translate}}</a></li>
        <li><a href="https://amerikadaniste.az/buy-for-me" target="_self">{{'ORDERS_BUY_FOR_ME' | translate}}</a></li>
        <li><a href="https://blog.amerikadaniste.com/" target="_self">{{'BLOG' | translate}}</a></li>
        <li><a href="https://yardim.amerikadaniste.com" target="_self">{{'FAQ' | translate}}</a></li>
        <li><a href="https://amerikadaniste.az/contact"  target="_self">{{'CONTACT_POLICY' | translate}}</a></li>
      </ul>
    </div>
    <div class="right-side-container" id="right-side-container">
      <div class="search-container" *ngIf="showSearchBar">
        <div class="search-field">
            <input class="search-input"  
            #searchParam 
            (keydown.enter)="search(searchParam.value)" 
            placeholder="{{'TYPE_THE_PRODUCT_THAT_YOU_WANT_TO_SEARCH' | translate}}"
            id="search-input" 
            (focus)="onSearchInputFocus()" 
            (focusout)="onSearchInputLostFocus()">
            <img  src="assets/icons/icon-search.png"  (click)="search(searchParam.value)" style="cursor: pointer;margin-left: -16px;padding-bottom: 5px;" async>
        </div>      
      </div>
      <div class="buttons-container">
        <div class="shopping-cart" *ngIf="cartItemCount > 0">
          <div class="cart-item" (click)="goToBasket()">
              <img class="cart-img" src="/assets/icons/ai-icon-black.png" async>
              <div class="basket-count">
                {{cartItemCount}}
              </div>
          </div>
        </div>
        <div class="shopping-cart empty-basket" *ngIf="cartItemCount === 0">
          <div class="cart-item" (click)="goToBasket()">
              <img class="cart-img" src="/assets/icons/empty-basket.png" async>
              <div class="basket-count">
                {{cartItemCount}}
              </div>
          </div>
        </div>
        <button *ngIf="auth === undefined || auth === null"  (click)="goToAzSignIn()"
                class="login-button">{{'SIGNIN' | translate}}
        </button>
        <button *ngIf="auth" (clickOutside)="menuUserShow = false" (click)="menuUserShow = !menuUserShow" class="user-button">
          <img class="user-icon" src="/assets/icons/user-icon.png" async/><img class="user-arrow" src="/assets/icons/dropdown-arrow.png" async>
        </button>
        <!-- <button  class="language-button" (click)="changeLanguage(langValue)">{{langButtonText}}</button> -->
      </div>
    </div>
    <div class="burger-menu menu-panel"  *ngIf="burgerMenuShow">
      <button class="mat-menu-item" (click)="navigateAzRouter(['shopping'])">{{'SHOPPING' | translate}}</button>
      <button class="mat-menu-item" (click)="navigateAzRouter(['prices'])">{{'PRICES' | translate}}</button>
      <button class="mat-menu-item" (click)="navigateAzRouter(['how-it-works'])">{{'HOW_IT_WORKS' | translate}}</button>
      <button class="mat-menu-item" (click)="openBfm()">{{'ORDERS_BUY_FOR_ME' | translate}}</button>
      <button class="mat-menu-item" (click)="openBlog()">{{'BLOG' | translate}}</button>
      <button class="mat-menu-item" (click)="openSSS()">{{'FAQ' | translate}}</button>
      <button class="mat-menu-item" (click)="navigateAzRouter(['/contact'])">{{'CONTACT_POLICY' | translate}}</button>
      <button *ngIf="auth !== undefined && auth !== null" class="mat-menu-item" (click)="navigateAzRouter(['/user/mailbox/list'])">{{'MAILBOX_US' | translate}} {{usMailBoxCount == 0 ? null : '(' + usMailBoxCount + ')'}}</button>
      <button *ngIf="auth !== undefined && auth !== null" class="mat-menu-item" (click)="navigateAzRouter(['user/orders/shopping'])">{{'ORDERS' | translate}}</button>
      <button *ngIf="auth !== undefined && auth !== null" class="mat-menu-item" (click)="navigateAzRouter(['/user/my-account/my-membership-information'])">{{'MY_ACCOUNT' | translate}}</button>
      <button *ngIf="auth !== undefined && auth !== null" class="mat-menu-item" (click)="navigateAzRouter(['user/favourites/list'])">{{'FAVOURITE_PRODUCTS' | translate}}</button>
      <div  class="mat-menu-item menu-button-container">
        <button *ngIf="auth === undefined || auth === null"  class=" signin-button" (click)="navigateAzRouter(['signin'])">{{'SIGNIN' | translate}}</button>
        <button *ngIf="auth === undefined || auth === null"  class=" signup-button" (click)="navigateAzRouter(['signup'])">{{'SIGNUP' | translate}}</button>
        <button *ngIf="auth !== undefined && auth !== null"  class=" signup-button logout-button" (click)="logout()">{{'LOG_OUT' | translate}}</button>
      </div>
      <!-- <div  class="mat-menu-item menu-button-container">
        <button class="  lang-flag-button" (click)="changeLanguage('en')" *ngIf="langValue === 'en'">
          <img src="/assets/icons/us-flag.png" async>
        </button>
        <button  class=" lang-flag-button" (click)="changeLanguage('az')" *ngIf="langValue === 'az'">
          <img src="/assets/icons/az-flag.png" async>
        </button>
      </div> -->
    </div>
    <div class="user-panel"  *ngIf="menuUserShow">
      <div class="user-panel-content">
      <div class="mat-menu-item user-info-item" (click) = "navigateRouter([currentLang +'/user/my-account/my-membership-information'])" *ngIf="userData">
        <div class="row-layout-space-between-center user-info-container">
          <img class="user-inside-menu-icon" src="assets/icons/user.png" async/>
          <div class="column-layout-start user-info">
            <div class="user-info-text">{{userData.Name}} {{userData.Surname}}</div>
            <div class="user-info-text">{{userData.Mail}}</div>
          </div>
        </div>
      </div>
      <button class="mat-menu-item"  (click) = "navigateRouter([currentLang +'/user/orders/shopping'])"><img src="assets/icons/order.png" async/>{{'ORDERS' | translate}}</button>
      <button class="mat-menu-item" (click) = "navigateRouter([currentLang +'/user/mailbox/list'])"><img src="assets/icons/mailbox.png" async/>{{'MAILBOX' | translate}}</button>
      <button class="mat-menu-item" (click) = "navigateRouter([currentLang +'/user/buy-for-me/new'])"><img src="assets/icons/bfm.png" async/>{{'ORDERS_BUY_FOR_ME' | translate}}</button>
      <button class="mat-menu-item" (click) = "navigateRouter([currentLang +'/user/my-account/my-membership-information'])"><img src="assets/icons/user.png" async/>{{'MY_ACCOUNT' | translate}}</button>
      <button class="mat-menu-item" (click) = "navigateRouter([currentLang +'/user/awards/list'])"><img src="assets/icons/prizes.png" async/>{{'MY_AWARDS' | translate}}</button>
      <button class="mat-menu-item" (click)="navigateRouter([currentLang +'/user/favourites/list'])"><img src="assets/icons/black-heart.png" async/>{{'FAVOURITE_PRODUCTS' | translate}}</button>
      <button class="mat-menu-item" (click)="logout()"><img src="assets/icons/logout-icon.png" async/>{{'LOG_OUT' | translate}}</button>
    </div>
    </div>
  </div>
  <div class="mobile-search-container" *ngIf="showMobileSearchBar">
    <div class="mobile-search-field">
      <input class="search-input"  
      #searchParam 
      (keydown.enter)="search(searchParam.value)" 
      placeholder="{{'TYPE_THE_PRODUCT_THAT_YOU_WANT_TO_SEARCH' | translate}}"
      id="search-input" >
      <img  src="assets/icons/icon-search.png"  (click)="search(searchParam.value)" style="cursor: pointer;margin-left: -16px;padding-top: 10px; width: 16px; height:16px;" async>
  </div>         
  </div>
</div>