@defer (on idle) {
<app-spinner></app-spinner>
}

<div class="floating-button-container" *ngIf="!auth">
  <a (click)="onSignUp()">
    <img [src]="'../assets/icons/signup-icon-' + translate.currentLang + '.png'" alt="">
  </a>

</div>
<div #body style="width: 100%">

  <!--<app-shoutbar></app-shoutbar>-->
  @defer (on idle) {
  <app-header [showSearchBar]="isShowSearchBar" [showMobileSearchBar]="isShowMobileSearchBar"></app-header>
  }
  
  <div class="router-wrapper" id="routerWrapper">
    <router-outlet style="width: 100%; overflow-x: hidden;" (activate)="onActivate($event)"></router-outlet>
  </div>
  @defer(on idle) {
  <app-footer></app-footer>
  }


</div>


<div *ngIf="consent !== 'true'">
  <div *ngIf="translate.currentLang === 'tr'" style="position: relative; z-index: 999999999999">
    <div style="background: #000000; color:#ffffff; position: fixed; bottom: 0; left: 0; right: 0; opacity: 0.85;"
      class="cookie-layout" ng-hide="consent()">
      <p style="margin-top: 1rem;margin-bottom: 1rem;margin-left: 1rem; font-size: 15px;">Deneyiminizi iyileştirmek için
        yasal
        düzenlemelere uygun çerezler (cookies) kullanıyoruz. Detaylı bilgiye<a style="color:blue"
          href="/privacy-policy"> Gizlilik
          Politikası </a> ve <a style="color:blue" href="/cookies-policy">Çerez Politikası</a> sayfalarımızdan
        erişebilirsiniz.</p>
      <div (click)="setConsent()" class="accept-button">Kabul Et</div>
    </div>
  </div>

  <div *ngIf="translate.currentLang === 'en'" style="position: relative; z-index: 999999999999">
    <div style="background: #000000; color:#ffffff; position: fixed; bottom: 0; left: 0; right: 0; opacity: 0.85;"
      class="cookie-layout" ng-hide="consent()">
      <p style="margin-top: 1rem;margin-bottom: 1rem;margin-left: 1rem; font-size: 15px;">We use cookies that comply
        with
        legal regulations to improve your experince. Learn details by reading our<a style="color:blue"
          href="/privacy-policy"> Privacy
          Policy </a> ve <a style="color:blue" href="/cookies-policy">Cookie Policy </a> pages.</p>
      <div (click)="setConsent()" class="accept-button">Accept</div>
    </div>
  </div>
</div>
<div id="minipopup" class="mini-popup">
  <div class="minipopup-user-avatar">
    <img src="../assets/icons/user-icon.png" alt="">
  </div>
  <div class="minipopup-message" *ngIf="translate.currentLang === 'tr'">
    <p>
      <span>{{minipopupUserName}} </span> <span>{{minipopupUserSurname}}'<span>{{minipopupSurnameApostrophe}} </span>
      </span>
      <span>{{minipopupBrand}}</span>
      siparişi <span class="bronz">kargoya verildi</span>
    </p>
  </div>
  <div class="minipopup-message" *ngIf="translate.currentLang === 'en'">
    <p>
      <span>{{minipopupUserName}} </span> <span>{{minipopupUserSurname}}'s </span> <span>{{minipopupBrand}}</span>
      order <span class="bronz">has been shipped</span>
    </p>
  </div>

</div>