
<div class="main-container">
  <div class="warning-message" *ngIf="warningMessage !== ''"
    style="text-align: center;background-color: #f9f9f9;padding: 12px;">
    <span style="font-size: 16px;">{{warningMessage}}</span>
  </div>
  @defer(when mainSliderList.length > 0) {
  <app-carousel [slideStore]="mainSliderList"></app-carousel>
  }
  @placeholder {
  <div class="slider-empity"></div>
  }
  @loading {
  <div class="slider-empity"></div>
  }



  <div *ngIf="!paidMember" class="try-free">
    <div class="try-free-label" [innerHtml]="'FREE_TRIAL_INFO' | translate"></div>
    <div class="try-free-button-container">
      <a (click)="navigateRouter(['signup'])" class="try-free-button"><span class="try-free-button-text">{{'FREE_TRIAL'
          |
          translate}}</span></a>
    </div>
  </div>
  <div class="buy-for-me-header">
    <div class="wrapper">
      <div class="col-1">
        <div class="text-container">
          <label>{{'BUY_FOR_ME' | translate}}</label>
          <small>{{'MAIN-PAGE-BFM-TEXT' | translate}}</small>
        </div>
      </div>
      <div class="col-2">
        <div class="wrapper-2">
          <input type="text" #addProductLink
            placeholder="{{'FOR_EXAMPLE' | translate}}: https://www.amazon.com/IWOLLANCE-Womens-Henley-Blouse">
          <div class="button-wrapper">
            <button (click)="navigateRouter(['signup'],true)">{{'BUY' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="my-america-address">
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <label>{{'MY_US_ADDRESS' | translate }}</label>
        <div class="buttons" style="display: flex;align-items: center;">
          @if(isAuth) {
          <img style="margin-left: 12px; cursor:pointer;height: 20px;" src="/assets/icons/icon-clipboard.png" title="copy"
            (click)="copyUsAddress()" />
          }
          <mat-icon matTooltip="{{'YOU_CAN_USE_THIS_ADDRESS' | translate}}  " #tooltip="matTooltip"
            (click)="tooltip.toggle()" matTooltipClass="mat-tooltip-info"
            style="margin-left: 12px;color:#888;">info_outline</mat-icon>
            @if(isAuth) {
              <div class="mail" (click)="usMailboxCountClick()">
                <!--<mat-icon style="color: #999;">mail_outlined</mat-icon>-->
                <div class="count" title="{{'CURRENT_STANDBY_TIME' | translate}} {{packageWaitingTime}} {{'DAY' | translate}}">{{usMailBoxCount}}</div>
              </div>
            }
        </div>
      </div><br>
      @if(isAuth) {
      <span>{{currentUser?.UsAddress?.AddressOwner}}</span><br>
      <span>{{currentUser?.UsAddress?.AddressLine1 + ' ' + currentUser?.UsAddress?.AddressLine2 + ' ' +
        currentUser?.UsAddress?.City + ', ' + currentUser?.UsAddress?.State + ' ' + currentUser?.UsAddress?.ZipCode}}
      </span><br>
      <span>{{currentUser?.UsAddress?.USPhone + ' / ' + currentUser?.UsAddress?.USPhoneExt }}</span>
      }
      @else {
      <span>{{'MAIN_US_ADDRESS_1' | translate}} </span>
      }

    </div>
  </div>
  @defer (on idle){
  <app-defer-main />
  }
</div>